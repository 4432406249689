<template>
  <nav
      id="categories"
      :style="navStyles"
      class="categories"
  >
    <ul class="categories-list">
      <!--      <client-only>-->
      <li class="categories-type">
        <img alt="soldier"
             class="soldier-icon"
             loading="lazy"
             src="/img/soldier.svg"
        />
        <span class="soldier-title">{{ t('Items type') }}</span>
      </li>
      <!--      </client-only>-->

      <li
          v-for="(catItem, index) in categories"
          :key="catItem.category"
          v-click-outside="close"
          :class="{'is-last': index === Object.keys(categories).length - 1}"
          class="list-item f"
      >
        <button
            :class="{ open: activeCategory === catItem.category }"
            class="category f"
            role="button"
            @click.stop="open(catItem.category)"
        >
          <span class="category-name">{{
              t(`categories.${catItem.category}`)
            }}</span>
          <i class="i-arrow category-arrow"></i>
        </button>
        <ul
            v-if="catItem.category?.toLowerCase() !== 'other'"
            :class="{ open: activeCategory === catItem.category }"
            class="category-dropdown"
        >
          <li v-if="!excludedCategories.includes(catItem.category?.toLowerCase())"
              class="category-item">
            <NuxtLink
                :to="getAllWeaponsPageLink(catItem.category || '')"
                class="category-link-all f"
            >
              <div class="category-link-all-images">
                <img
                    v-for="item in (catItem.items as Item[]).slice(-4)"
                    :src="getImageFromWaxpeer(item?.full_name || '', '60:60')"
                    alt=""
                    class="link-icon"
                    height="24"
                    loading="lazy"
                    width="24"
                />
              </div>
              <span class="link-name">{{ $t('filters.weapon.all.' + catItem.category) }}</span>
            </NuxtLink>
            <!--            <span class="hr db"></span>-->
          </li>
          <li
              v-for="(item) in (catItem.items as Item[])"
              :key="item?.full_name + item?.weapon"
              class="category-item"
              @click="close"
          >
            <CategoriesCategoryLink
                :fullname="item?.full_name || ''"
                :weapon="item?.weapon || ''"
            />
          </li>
        </ul>

        <LazyCategoriesOtherMenu
            v-else
            :categories="catItem.items as Item[]"
            :class="{ open: activeCategory === catItem.category }"
            class="category-dropdown"
        />
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from 'vue'
import {useI18n} from 'vue-i18n'
import {useRoute} from 'vue-router'
import {useResize} from '~/composables/resize.js'
import {useStore} from '~/store/index.js'
import {useGtag} from "#imports";
import {getAllWeaponsPageLink} from "~/utils/name-utils";
import type {Item} from "~/graphql/generated/schema-types";

const emit = defineEmits<{
  (e: 'close'): void
}>()

const {t, locale} = useI18n()
const store = useStore()
const currW = useResize()
const route = useRoute()
const {gtag} = useGtag()

const activeCategory = ref<string | null>(null)
const path = ref(route.path)
const excludedCategories = ['equipment', 'other']

const currPath = computed(() => {
  return route.path
})
const categories = computed(() => store.categories)

watch(currW, () => {
  if (currW.value > 1115) {
    activeCategory.value = null
  }
})

const navStyles = ref({})
const isCatalogPresent = ref(false);

const observeCatalogElement = () => {
  if (!process.client) return; // Проверка, что код выполняется на клиенте

  const catalogObserver = new MutationObserver(() => {
    isCatalogPresent.value = !!document.querySelector('.catalog');
  });

  onMounted(() => {
    catalogObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Начальная проверка наличия элемента
    isCatalogPresent.value = !!document.querySelector('.catalog');
  });

  onBeforeUnmount(() => {
    catalogObserver.disconnect();
  });
};

observeCatalogElement();

watch([currPath, isCatalogPresent, currW], () => {
  if (
      currPath.value?.includes('/comparison')
      || isCatalogPresent.value
      || (currPath.value?.includes('/search') && currW.value >= 1115)
  ) {
    navStyles.value = {
      position: 'fixed',
      background: '#101319',
    };
  } else {
    navStyles.value = {};
  }
});

const open = (brand: string) => {
  activeCategory.value = activeCategory.value === brand ? null : brand
  gtag('event', 'csmarket-click', {
    event_category: 'Show Category',
    event_label: 'With header',
    value: brand
  })
}

const close = () => {
  if (activeCategory.value) {
    activeCategory.value = null
    emit('close')
  }
}
</script>

<style lang="scss" scoped>

@use '/assets/scss/mixins.scss' as *;
@use 'sass:color';

ul {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

.categories {
  position: absolute;
  width: 100%;
  margin-top: 75px;
  z-index: 2;
  @include unselectable;

  &-type {
    min-width: 13rem;
    padding: 0 15px;
    height: 4rem;
    background: linear-gradient(
            101.99deg,
            rgba(88, 226, 176, 0.1) -3.52%,
            rgba(62, 176, 211, 0.1) 89.43%
    );
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4rem;

    .soldier-title {
      margin-left: 0.89rem;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.9rem;
      background: linear-gradient(101.99deg, var(--accent-primary) -3.52%, #3eb0d3 89.43%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    @media screen and (max-width: 1400px) {
      display: none;
    }
  }

  &-list {
    height: 7.2rem;
    width: 100%;
    display: flex;
    overflow: visible;
    //padding-bottom: 3rem;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--surfaces-high-container);
    border-bottom: 1px solid var(--surfaces-high-container);
    gap: 3.2rem;

    @media screen and (max-width: 1700px) {
      gap: 1.8rem;
    }

    @media screen and (max-width: 1500px) {
      gap: 1rem;
    }

    @media screen and (max-width: 1350px) {
      gap: 0;
    }

    .list-item {
      min-width: auto;
      font-size: 1.5rem;
      position: relative;
      cursor: pointer;

      .category {
        padding: 0.1rem 0.8rem;
        height: 100%;

        &-name {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;

          font-weight: 600;
          font-size: 1.4rem;
          line-height: 1.9rem;
          color: var(--surfaces-on-surface-variable);
        }

        &-arrow {
          margin-left: 1rem;
          font-size: 1rem;
          color: var(--surfaces-on-surface);
          opacity: 0.3;
        }

        &-dropdown {
          position: absolute;
          top: 4.8rem;
          background: var(--surfaces-low-container);
          box-shadow: 0 4px 20px rgba(26, 26, 26, 0.7);
          //border-radius: 1.2rem;
          //padding: 0 0.3rem;
          overflow-y: auto;
          min-width: 22rem;
          transition: all 0.3s;
          max-height: 0;
          max-width: max-content;
          width: max-content;

          background: var(--surfaces-container);
          //background: red;
          font-size: 14px;
          padding: 0;
          border-radius: 12px;

          /* width */
          &::-webkit-scrollbar {
            width: 12px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            border-radius: 10px;
            padding: 4px;
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: var(--surfaces-outline);
            border-radius: 4px;
            border: 4px solid transparent; // Создает эффект узкого `thumb`
            background-clip: padding-box; // Центрирует `thumb` внутри скроллбара
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background-color: color-mix(IN srgb, var(--surfaces-outline) 95%, white);
          }

          .category-item {
            //margin-bottom: -2px;
            ////display: flex;
            //padding-left: 1.2rem;
            //padding-right: 1.2rem;
            //height: 5.4rem;
            //
            //&:last-child:deep(.category-link) {
            //  border-bottom: none;
            //}

            //&:hover {
            //  background: #1d212b;
            //}
          }

        }

        //&:hover .category-name {
        //  opacity: initial;
        //  color: var(--surfaces-on-surface);
        //}
        //
        //&:hover .category-arrow {
        //  color: var(--surfaces-on-surface);
        //  opacity: 1;
        //}

        &-link-all {
          display: inline-flex;
          align-items: center;
          padding: 4px 16px;
          width: 100%;
          gap: 16px;
          border-radius: 0 8px 8px 0;

          .category-link-all-images {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            width: 50px;
            height: 50px;
            flex-shrink: 0;
            flex-grow: 0;

            background: radial-gradient(50% 50% at 50% 50%, color-mix(in srgb, var(--accent-on-surface) 40%, transparent) 0%, transparent 100%);

            img {
              object-fit: cover;
            }
          }

          .link-name {
            font-weight: 400;
            font-size: 1.4rem;
            color: var(--surfaces-on-surface-variable);
            //margin-left: 1.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 100%;
            display: inline-block;
            text-align: left;
            line-height: 5rem;
          }

          &:hover, &.router-link-exact-active {
            background: color-mix(IN srgb, var(--surfaces-container) 95%, white);

            .link-name {
              color: var(--accent-secondary);
            }
          }
        }
      }

      &.is-last .category-dropdown {
        right: 0;
        left: unset;
      }

      .open {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        max-height: 43.8rem;
      }
    }

    .open .category-arrow {
      transform: rotate(180deg);
    }
  }
}

@media screen and (max-width: 1115px) {
  .categories {
    margin: 0;
    padding-right: 0;
    position: initial;
    width: 100%;
    @include unselectable;

    &-list {
      flex-direction: column;
      border: none;
      height: fit-content;
      gap: 2.2rem;

      .list-item {
        width: 100%;
        padding-left: 2.5rem;
        padding-right: 3.65rem;
        display: flex;
        flex-direction: column;

        .category {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;
        }

        .category-dropdown {
          position: initial;
          top: initial;
          background: none;
          box-shadow: none;
          padding: 0;
          overflow-y: hidden;
          width: 100%;
          margin-top: 1.1rem;

          .category-item {
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            height: unset;

            &:hover {
              background: none;
            }

            &:hover .link-name {
              color: var(--surfaces-on-surface);
            }

            .link-name {
              line-height: 2.8rem;
            }
          }

        }

        .open {
          max-height: 100rem;
        }

        .open .category-name {
          color: var(--surfaces-on-surface);
        }

        .open .category-arrow {
          color: var(--surfaces-on-surface);
          opacity: 1;
        }
      }
    }
  }
}
</style>
